  import React from 'react'
  import Layout from '../../../components/App/Layout'
  import Navbar from '../../../components/App/NavbarRV'
  import Footer from '../../../components/App/FooterRV'
  import KDPImagePage from '../../../assets/images/pages/3-10-0-0.jpg'
  
  const Details = () => {
      return (
          <Layout>
              <Navbar />
              <div>
                  <div className='container'>
                      <div className='page-title-content'>
                          <ul>
<li><a href='/'>Accueil</a></li><li><a href='/paca/'>Trouvez un expert près de chez vous</a></li><li>Expert fissure Aix en provence</li>
                          </ul>
                      </div>
                  </div>
              </div>
              <section className="services-details-area ptb-100">
                  <div className="container">
                      <div className="row">
                          <div className="col-lg-8 col-md-12">
                              <div className="services-details-desc">
                                
<h1>Expertise fissure à Aix-en-Provence : Quand faire appel à un Expert fissure ? </h1>

<p>L’Expert fissure est un professionnel du bâtiment maîtrisant les techniques de l’ingénieur et la connaissance des pathologies de la construction.</p>
<div className='services-details-image'>
  <img src={KDPImagePage} alt="Expert fissure Aix en provence" />
</div>


<p>Un Expert technique fissures a pour mission d’évaluer les dommages de type “fissures” affectant votre ouvrage. </p>
<p>Il détermine les causes et les origines des désordres et préconise les travaux de travaux de réparation les plus adaptés afin de recouvrer un ouvrage structurellement sain et pérenne.</p>

<p>Il accompagne également les assurés lors d’opérations de contre-expertise amiable ou en contexte judiciaire afin de <u>défendre les intérêts de l'assuré.</u></p>

<h2>L’Expertise technique fissure et expertise d’assuré </h2>

<p>L’Expert technique fissure intervient dans le cadre d’une expertise unilatérale, c'est-à-dire pour les besoins du client concernant ses problématiques de fissures sur ouvrage.</p>

<ul><li>D’où proviennent ces fissures ?</li>
<li>Ces fissures sont-elles “graves” ? </li>
<li>Engagent-elles la solidité structurelle de l’ouvrage ?</li>
<li>Quels sont les travaux de réparation les plus adaptés ?</li></ul>

<p>L’Expert d’assuré “fissures”, quant à lui, intervient lors de litiges ou conflits l’opposant à un constructeur ou une assurance, par exemple dans le cadre d’un litige de dossier CatNat sécheresse ayant généré des fissures sur un ouvrage.</p>

<p>Il accompagne l’assuré techniquement et en médiation dans un objectif de résolution amiable du conflit afin que son sinistre soit indemnisé par son assurance qui aurait injustement prononcé un refus de garantie.</p>

<h2>L’intervention d’un expert fissure, c’est l’assurance d’être bien accompagné</h2>

<p>Depuis 2018, le cabinet RV Expertises s’est spécialisé dans les problématiques d’expertises de fissures sur ouvrages suite à des épisodes de sécheresse ou pour des avis techniques indépendants.</p>

<p>Nous effectuons une expertise in situ, déterminons les causes et origines des fissures afin de définir les solutions techniques réparatoires les plus adaptées pour vous assurer une réparation pérenne et définitive. </p>


<div className="others-option d-flex align-items-center">
    <div className="option-item">
        <a class="default-btn" href="/contact/"><i className="flaticon-right"></i> Contacter un Expert fissure à Aix-en-Provence</a>
    </div>
</div>



                                
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-12">
                              <div className="services-details-info">
                                  <ul className="services-list">
                                      
<li><a href='/paca/expert-fissure-aix-en-provence/' className='active'>Expert fissure Aix en provence</a></li>
                                      
                                  </ul>
                                  <a href='javascript:history.go(-1)'>Retour</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
              <Footer />
          </Layout>
      );
  }

  export default Details